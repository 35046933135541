import React from 'react';
import Content from '../views/Fleet/Main';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Prøv næste generations flådestyringsløsning til virksomheder",
    lang: 'da-DK',
    description: "Prøv flådestyring tjenesten - Fleet. Det vil forenkle dine opgaver såsom sporing af biler, vedligeholdelse mm. Få et bedre overblik over din bilpark.",
    meta: [{
      name: 'keywords',
      content: 'Flådestyringsløsning'
    }]
  }}/>
);